<template>
  <div class="mx-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
      <b-col align="right">
        <b-button
          class="btn-create"
          type="button"
          variant="primary"
          size="sm"
          @click="onAdd"
          title="Crea"
          v-if="canVerb(resource, 'store')"
          ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
          Crea</b-button
        >
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <div class="mt-2" id="collapse-1">
        <b-form @submit.prevent="onSearch(filterName)">
          <b-card class="filter">
            <b-row>
              <div class="col-md-3">
                <base-input
                  name="AGFRNAME"
                  v-model="filter.byCustom.title.value"
                  type="text"
                  label="Nominativo"
                  placeholder="Inserisci un nominativo"
                />
              </div>
              <div class="col-md-3">
                <base-input
                  name="NINO"
                  v-model="filter.byAttribute.NINO"
                  type="text"
                  label="Codice Fiscale"
                  placeholder="Inserisci un codice fiscale"
                />
              </div>
            </b-row>
            <b-row>
              <div class="col-md-3">
                <base-input
                  name="CREG"
                  v-model="filter.byAttribute.CREG"
                  type="text"
                  label="Partita IVA"
                  placeholder="Inserisci una partita IVA"
                />
              </div>
              <div class="col-md-3">
                <base-select
                  name="CLI"
                  label="Cliente"
                  v-model="filter.byAttribute.CLI"
                  :options="opt_sup_cli"
                  :taggable="false"
                  :multiple="false"
                  :closeOnSelect="true"
                />
              </div>
              <div class="col-md-3">
                <base-select
                  name="SUP"
                  label="Fornitore"
                  v-model="filter.byAttribute.SUP"
                  :options="opt_sup_cli"
                  :taggable="false"
                  :multiple="false"
                  :closeOnSelect="true"
                />
              </div>
            </b-row>
            <b-row>
              <div class="form-group col-md-3 align-self-end">
                <b-button
                  type="submit"
                  variant="lisaweb"
                  size="sm"
                  v-b-toggle:collapse-1
                  >Cerca</b-button
                >
                <b-button
                  class="btn-reset"
                  type="button"
                  variant="lisaweb"
                  size="sm"
                  @click="onClearFilter(filterName)"
                  >Reset</b-button
                >
              </div>
            </b-row>
            <b-button-group
              v-b-toggle:collapse-1-inner
              class="my-2 filter-button-group"
            >
              <span class="when-open">
                <b-icon icon="funnel" font-scale="1.5"></b-icon>
              </span>
              <span class="when-closed">
                <b-icon icon="funnel" font-scale="1.5"></b-icon>
              </span>
              Altri Filtri
            </b-button-group>
            <b-collapse id="collapse-1-inner" class="mt-2">
              <b-row>
                <div class="col-md-3">
                  <base-input
                    name="PHON"
                    v-model="filter.byAddressBook.PHON"
                    type="text"
                    label="Telefono"
                    placeholder="Inserisci un numero di telefono"
                  />
                </div>
                <div class="col-md-3">
                  <base-input
                    name="CELL"
                    v-model="filter.byAddressBook.CELL"
                    type="text"
                    label="Cellulare"
                    placeholder="Inserisci un numero di cellulare"
                  />
                </div>
                <div class="col-md-3">
                  <base-input
                    name="FAX"
                    v-model="filter.byAddressBook.FAX"
                    type="text"
                    label="Fax"
                    placeholder="Inserisci un fax"
                  />
                </div>
                <div class="col-md-3">
                  <base-input
                    name="MAIL"
                    v-model="filter.byAddressBook.MAIL"
                    type="text"
                    label="Email"
                    placeholder="Inserisci una mail"
                  />
                </div>
                <div class="col-md-3">
                  <base-select
                    name="PROVINCE"
                    label="Provincia"
                    v-model="filter.province"
                    :options="opt_provinces"
                    :taggable="false"
                    :multiple="false"
                    :closeOnSelect="true"
                    @select="onSelectProvince"
                    @remove="onRemoveProvince"
                  />
                </div>
                <div class="col-md-3">
                  <base-select
                    name="CITY"
                    label="Comune"
                    v-model="filter.byCorrespondence.CITY"
                    :options="opt_councils"
                    :taggable="false"
                    :multiple="false"
                    :closeOnSelect="true"
                  />
                </div>
                <div class="col-md-3">
                  <base-input
                    name="CAP"
                    v-model="filter.byCorrespondence.ZIPC"
                    type="text"
                    label="CAP"
                    maxlength="5"
                    placeholder="Inserisci un CAP"
                  />
                </div>
                <div class="col-md-3">
                  <base-textarea
                    name="ADDR"
                    label="Indirizzo"
                    v-model="filter.byCorrespondence.ADDR"
                    placeholder="Inserisci un indirizzo"
                  />
                </div>
              </b-row>
              <div v-show="customFilters.length > 0">
                <p>Attributi personalizzati</p>
                <b-row v-for="(element, index) in customFilters" :key="index">
                  <custom-filter
                    v-model="filter.byAttribute[element.key]"
                    :element="element"
                  />
                </b-row>
              </div>
            </b-collapse>
          </b-card>
        </b-form>
      </div>
    </b-collapse>
    <!-- <export-options
      :exportUrl="exportUrl"
      :repository="repository"
      :resource="resource"
      :tableRef="$refs[tableRef]"
      :filter="filter"
      :options="[{
        code: 'EXPOAGCO',
        label: null,
        formats: ['csv'],
      }]"
    ></export-options> -->
    <!-- <button-group-table></button-group-table> -->

    <table-agency-contacts
      @edit="onEdit"
      @open_note="openNoteModal"
      @open_todo="openTodoModal"
      @open_appointment="openAppointmentModal"
      @open_document="openDocumentModal"
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :filterOn="{
        byRelations: [
          'byCorrespondence',
          'byAddressBook',
          'byDocument',
          'byTask',
        ],
      }"
      :onlyActions="['edit', 'destroy', 'infomodal']"
      @destroy="onDestroy"
      :ref="tableRef"
    ></table-agency-contacts>
    <todo-detail-modal :items="todos"> </todo-detail-modal>
    <appointment-detail-modal :items="appointments"> </appointment-detail-modal>
    <note-detail-modal :items="notes"> </note-detail-modal>
    <document-detail-modal :items="documents"> </document-detail-modal>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
// import ButtonGroupTable from "@/components/ButtonGroupTable";
// import ExportOptions from "@/components/ExportOptions";
import TableAgencyContacts from "@/components/tables/AgencyContactsWallet";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import BaseTextarea from "@/components/form/BaseTextarea";
import TodoDetailModal from "@/components/modals/todoDetailModal";
import AppointmentDetailModal from "@/components/modals/appointmentDetailModal";
import NoteDetailModal from "@/components/modals/noteDetailModal";
import DocumentDetailModal from "@/components/modals/documentDetailModal";
import CustomFilter from "@/components/form/CustomFilter";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { toRelationString /* , toInfoData */ } from "@/utils/transforms";
import { mapGetters, mapActions } from "vuex";

export default {
  mixins: [CustomFiltersMixin],
  data() {
    return {
      // exportUrl: null,
      repository: "agency_contact",
      resource: "agency_contacts",
      filterName: "filterAgencyContact",
      notes: [],
      todos: [],
      appointments: [],
      documents: [],
      filter: this.initFilter(),
      defaultCountryName: "Italia",
      opt_councils: [],
      opt_provinces: [],
      opt_sup_cli: [
        { text: "Si", value: "Y" },
        { text: "No", value: "N" },
      ],
      opt_agency_contacts_type: [
        { value: "0", text: "Cliente" },
        { value: "1", text: "Fornitore" },
        { value: "2", text: "Fornitore/Cliente" },
      ],
      tableRef: "AgencyContactTableRef",
      fields: [
        {
          key: "attributables.CNAM",
          value: "attributables.CNAM",
          label: this.getDictionary("attribute_CNAM_value", "agency_contact"),
          sortable: true,
          sortKey: "CNAM",
        },
        {
          key: "attributables.NAME",
          value: "attributables.NAME",
          label: this.getDictionary("attribute_NAME_value", "agency_contact"),
          sortable: true,
          sortKey: "NAME",
        },
        {
          key: "attributables.SURN",
          value: "attributables.SURN",
          label: this.getDictionary("attribute_SURN_value", "agency_contact"),
          sortable: true,
          sortKey: "SURN",
        },
        {
          key: "attributables.NINO",
          value: "attributables.NINO",
          label: this.getDictionary("attribute_NINO_value", "agency_contact"),
          sortable: true,
          sortKey: "NINO",
        },
        {
          key: "attributables.CREG",
          value: "attributables.CREG",
          label: this.getDictionary("attribute_CREG_value", "agency_contact"),
          sortable: true,
          sortKey: "CREG",
        },
        {
          key: "city",
          label: this.getDictionary("city", "agency_contact"),
          value: (item) =>
            toRelationString(
              "correspondences",
              item.correspondences,
              ["CITY"],
              [0]
            ),
        },
        {
          key: "province",
          label: this.getDictionary("province", "agency_contact"),
          value: (item) =>
            toRelationString(
              "correspondences",
              item.correspondences,
              ["VIRTUAL_CITY_PROVINCE"],
              [0]
            ),
        },
        {
          key: "phone",
          label: this.getDictionary("phone", "agency_contact"),
          value: (item) =>
            toRelationString(
              "address_books",
              item.address_books,
              ["PHON"],
              [0]
            ),
        },
        {
          key: "mail",
          label: this.getDictionary("email", "agency_contact"),
          value: (item) =>
            toRelationString(
              "address_books",
              item.address_books,
              ["MAIL"],
              [0]
            ),
        },
        {
          key: "attributables.CLI",
          formatter: (value) => {
            return value === "Y" ? "Si" : "No";
          },
          label: this.getDictionary("attribute_CLI_value", "agency_contact"),
        },
        {
          key: "attributables.SUP",
          formatter: (value) => {
            return value === "Y" ? "Si" : "No";
          },
          label: this.getDictionary("attribute_SUP_value", "agency_contact"),
        },
        {
          key: "document_counter",
          label: this.getDictionary("Documenti"),
          value: (item) => (item.documents ? item.documents.length : 0),
        },
        {
          key: "note_counter",
          label: this.getDictionary("Note"),
          value: (item) =>
            item.tasks
              ? item.tasks.filter((task) => task.task_type.value === 2).length
              : 0,
        },
        {
          key: "todo_counter",
          label: this.getDictionary("Attività"),
          value: (item) =>
            item.tasks.length
              ? item.tasks.filter((task) => task.task_type.value === 1).length
              : 0,
          sortable: false,
        },
        {
          key: "appointment_counter",
          label: this.getDictionary("Appuntamenti"),
          value: (item) =>
            item.tasks.length
              ? item.tasks.filter((task) => task.task_type.value === 0).length
              : 0,
          sortable: false,
        },
      ],
    };
  },
  components: {
    BaseInput,
    TableAgencyContacts,
    // ButtonGroupTable,
    // ExportOptions,
    BaseSelect,
    CustomFilter,
    BaseTextarea,
    TodoDetailModal,
    AppointmentDetailModal,
    NoteDetailModal,
    DocumentDetailModal,
  },
  methods: {
    initFilter() {
      let init = {
        province: null,
        byAttribute: {
          status_agencycontact: 0,
          NAME: null,
          SURN: null,
          CNAM: null,
          NINO: null,
          CREG: null,
          CLI: null,
          SUP: null,
        },
        byCustom: {
          title: {
            value: null,
            likewise: 1,
            filter: "byAttribute",
          },
        },
        byAddressBook: {
          CELL: null,
          PHON: null,
          FAX: null,
          MAIL: null,
        },
        byCorrespondence: {
          CITY: null,
          ADDR: null,
          ZIPC: null,
        },
      };
      return init;
    },
    onEdit(id, item) {
      this.$router.push({
        name: `${this.resource}.edit0`,
        params: { id: `${id}`, item: item },
      });
    },
    onAdd() {
      this.$router.push({ name: `${this.resource}.create0` });
    },
    onDestroy(id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
        .then(() => {
          this.$refs[this.tableRef].fetch();
          this.$showSnackbar({
            preset: "success",
            text: "Contatto di agenzia Eliminato con successo",
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onSelectProvince(selected) {
      this.opt_councils = this.councils()(selected);
      this.filter.byCorrespondence.CITY = null;
    },
    onRemoveProvince() {
      this.opt_councils = [];
      this.filter.byCorrespondence.CITY = null;
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
      // this.onSearch(name);
    },
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
    openNoteModal(array) {
      this.notes = array;
      this.$bvModal.show("noteDetailModal");
    },
    openTodoModal(array) {
      this.todos = array;
      this.$bvModal.show("todoDetailModal");
    },
    openAppointmentModal(array) {
      this.appointments = array;
      this.$bvModal.show("appointmentDetailModal");
    },
    openDocumentModal(array) {
      this.documents = array;
      this.$bvModal.show("documentDetailModal");
    },
    ...mapGetters("auth", ["provinces", "councils", "countries"]),
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
  },
  created() {
    const defaultCountryId = this.countries().find(
      (country) => country.text == this.defaultCountryName
    ).value;
    this.opt_provinces = this.provinces()(defaultCountryId);
  },
};
</script>
