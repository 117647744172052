<template>
  <div class="mt-2">
    <div :style="{ width: !noInnerWidth ? width : 'auto' }">
      <div>
        <b-table
          :busy="isLoading"
          head-variant="light"
          :no-local-sorting="true"
          :items="items"
          :fields="getAllFields"
          :current-page="currentPage"
          per-page="0"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :sticky-header="height"
          no-border-collapse
          responsive
          show-empty
          empty-text="Non ci sono informazioni da mostrare"
          small
          @sort-changed="sortingChanged"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <base-icon name="loading" width="35" height="35" />
              <p>Operazione in corso...</p>
            </div>
          </template>
          <template #cell(phone)="{ item }">
            <div v-if="item['address_books']">
              {{
                toRelationString(
                  "address_books",
                  item.address_books,
                  ["PHON"],
                  [0]
                )
              }}
            </div>
          </template>
          <template #cell(province)="{ item }">
            <div v-if="item['correspondences']">
              {{
                toRelationString(
                  "correspondences",
                  item.correspondences,
                  ["VIRTUAL_CITY_PROVINCE"],
                  [0]
                )
              }}
            </div>
          </template>
          <template #cell(city)="{ item }">
            <div v-if="item['correspondences']">
              {{
                toRelationString(
                  "correspondences",
                  item.correspondences,
                  ["CITY"],
                  [0]
                )
              }}
            </div>
          </template>
          <template #cell(mail)="{ item }">
            <div v-if="item['address_books']">
              {{
                toRelationString(
                  "address_books",
                  item.address_books,
                  ["MAIL"],
                  [0]
                )
              }}
            </div>
          </template>
          <template #cell(note_counter)="{ item }" v-if="items.length">
            <div
              :role="
                item.tasks.filter((task) => task.task_type.value === 2).length
                  ? 'button'
                  : null
              "
              class="info-box float-left"
              @click="
                item.tasks.filter((task) => task.task_type.value === 2).length
                  ? openNoteDetailModal(
                      item.tasks.filter((task) => task.task_type.value === 2)
                    )
                  : null
              "
              v-b-tooltip="
                toTooltipTask(
                  item.tasks.filter((task) => task.task_type.value === 2),
                  'Note',
                  [
                    { label: 'Titolo', key: 'title' },
                    { label: 'Descrizione', key: 'description' },
                    {
                      label: 'Ultimo aggiornamento',
                      key: 'last_update',
                      formatter: (val) => {
                        return moment(val).format('DD/MM/YYYY HH:mm:ss');
                      },
                    },
                  ]
                )
              "
            >
              {{
                item.tasks
              ? item.tasks.filter((task) => task.task_type.value === 2).length
              : 0,
              }}
            </div>
          </template>
          <template #cell(document_counter)="{ item }" v-if="items.length">
            <div
              :role="item.documents && item.documents.length ? 'button' : null"
              class="info-box float-left"
              @click="
                item.documents && item.documents.length
                  ? openDocumentDetailModal(item.documents)
                  : null
              "
              v-b-tooltip="
                toTooltipTask(item.documents, 'Documenti', [
                  { label: 'Titolo', key: 'title' },
                  { label: 'Descrizione', key: 'note' },
                  {
                    label: 'Ultimo aggiornamento',
                    key: 'last_update',
                    formatter: (val) => {
                      return moment(val).format('DD/MM/YYYY HH:mm:ss');
                    },
                  },
                ])
              "
            >
              {{ item.documents ? item.documents.length : 0 }}
            </div>
          </template>
          <template #cell(todo_counter)="{ item }" v-if="items.length">
            <div
              :role="
                item.tasks.filter((task) => task.task_type.value === 1).length
                  ? 'button'
                  : null
              "
              class="info-box float-left"
              @click="
                item.tasks.filter((task) => task.task_type.value === 1).length
                  ? openTodoDetailModal(
                      item.tasks.filter((task) => task.task_type.value === 1)
                    )
                  : null
              "
              v-b-tooltip="
                toTooltipTask(
                  item.tasks.filter((task) => task.task_type.value === 1),
                  'Attività',
                  [
                    { label: 'Titolo', key: 'title' },
                    { label: 'Descrizione', key: 'description' },
                    {
                      label: 'Ultimo aggiornamento',
                      key: 'last_update',
                      formatter: (val) => {
                        return moment(val).format('DD/MM/YYYY HH:mm:ss');
                      },
                    },
                  ]
                )
              "
            >
              <!-- :title="
                  item.tasks.filter((task) => task.task_type.value === 1).length
                    ? 'Mostra Note'
                    : null
                " -->
              {{
                item.tasks.filter((task) => task.task_type.value === 1).length
              }}
            </div>
          </template>
          <template #cell(appointment_counter)="{ item }" v-if="items.length">
            <div
              :role="
                item.tasks.filter((task) => task.task_type.value === 0).length
                  ? 'button'
                  : null
              "
              class="info-box float-left"
              @click="
                item.tasks.filter((task) => task.task_type.value === 0).length
                  ? openAppointmentDetailModal(
                      item.tasks.filter((task) => task.task_type.value === 0)
                    )
                  : null
              "
              v-b-tooltip="
                toTooltipTask(
                  item.tasks.filter((task) => task.task_type.value === 0),
                  'Appuntamenti',
                  [
                    { label: 'Titolo', key: 'title' },
                    { label: 'Descrizione', key: 'description' },
                    {
                      label: 'Data e ora inizio',
                      key: 'started_at',
                      formatter: (val) => {
                        return moment(val).format('DD/MM/YYYY HH:mm:ss');
                      },
                    },
                    {
                      label: 'Data e ora fine',
                      key: 'ended_at',
                      formatter: (val) => {
                        return moment(val).format('DD/MM/YYYY HH:mm:ss');
                      },
                    },
                    {
                      label: 'Ultimo aggiornamento',
                      key: 'last_update',
                      formatter: (val) => {
                        return moment(val).format('DD/MM/YYYY HH:mm:ss');
                      },
                    },
                  ]
                )
              "
            >
              <!-- :title="
                  item.tasks.filter((task) => task.task_type.value === 0).length
                    ? 'Mostra Note'
                    : null
                " -->
              {{
                item.tasks.filter((task) => task.task_type.value === 0).length
              }}
            </div>
          </template>
          <template #cell(actions)="row">
            <div class="action-buttons">
              <b-button
                v-if="actions.includes('infomodal')"
                size="sm"
                variant="lisaweb"
                @click="openModal(row.item, row.index, $event.target)"
                class="mt-1 mr-1"
                title="Modale Dettagli"
              >
                <b-icon icon="eye"></b-icon>
              </b-button>

              <b-button
                v-if="actions.includes('details')"
                size="sm"
                variant="lisaweb"
                @click="row.toggleDetails"
                class="mt-1 mr-1"
                :title="
                  row.detailsShowing ? 'Chiudi dettagli' : 'Apri dettagli'
                "
              >
                <b-icon v-if="row.detailsShowing" icon="chevron-up"></b-icon>
                <b-icon v-else icon="chevron-down"></b-icon>
              </b-button>

              <b-button
                v-if="actions.includes('edit')"
                size="sm"
                variant="lisaweb"
                @click="onEdit(row.item.id, row.item)"
                class="mt-1 mr-1"
                title="Modifica"
              >
                <b-icon icon="pencil-square"></b-icon>
              </b-button>
              <b-button
                v-if="actions.includes('destroy')"
                size="sm"
                variant="lisaweb"
                @click="onDestroy(row.item.id)"
                class="mt-1 mr-1"
                title="Elimina"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </template>
          <template #cell(rowSelector)="row">
            <div class="action-buttons">
              <b-button
                size="sm"
                variant="lisaweb"
                @click="onChosen(row.item)"
                class="mt-1 mr-1"
                title="Seleziona"
              >
                Seleziona
              </b-button>
            </div>
          </template>
          <template #row-details="row">
            <b-card>
              <b-row
                class="mb-2"
                v-for="(value, key) in customized(row.item)"
                :key="key"
              >
                <b-col sm="3" class="text-sm-right"
                  ><b>{{ key | capitalize }}:</b></b-col
                >
                <b-col>{{ value }}</b-col>
              </b-row>
            </b-card>
          </template>
        </b-table>
      </div>
      <b-row>
        <div class="show-text">
          <p>Mostra</p>
        </div>
        <div class="group">
          <b-form-group
            label-for="per-page-select"
            label-cols-sm="3"
            label-cols-md=""
            label-cols-lg="3"
            label-size="sm"
            md="auto"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              style="width: 68px"
              size="sm"
              @change="onPageChange(1)"
              :disabled="!items.length"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="page">
          <p>di {{ totalItems }} per pagina</p>
        </div>
        <div class="pagination" v-if="totalItems">
          <b-pagination
            v-model="currentPage"
            limit="3"
            :total-rows="totalItems"
            :per-page="perPage"
            size="sm"
            @change="onPageChange"
          ></b-pagination>
        </div>
        <div class="input_pagination" v-if="totalItems && !noInputPaginator">
          <base-input-pagination
            @pagination="onInputPagination"
          ></base-input-pagination>
        </div>
      </b-row>
      <b-modal
        :id="infomodalName"
        @hidden="onModalHidden"
        :title="modalTitle"
        size="xl"
        scrollable
        ok-only
        ok-variant="lisaweb"
        header-bg-variant="lisaweb"
        no-close-on-backdrop
      >
        <div>
          <b-tabs content-class="pt-1" lazy v-model="tabIndex">
            <b-tab
              v-for="(tab, index) in tabs"
              :key="index"
              @click="setTab(tab, index)"
              :title="tab.text"
              :active="index === tabIndex"
              title-item-class="lisaweb"
            >
              <button-group-tab> </button-group-tab>
              <keep-alive>
                <component
                  v-if="selected === tab.name"
                  :is="selected"
                  :resourceId="modalResourceId"
                  @fetch="fetch"
                ></component>
              </keep-alive>
            </b-tab>
          </b-tabs>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import template from "./template";
import BaseIcon from "@/components/BaseIcon";
import ButtonGroupTab from "@/components/ButtonGroupTab";
import Todo from "@/components/wallet/agency-contacts/details/Todo";
import General from "@/components/wallet/agency-contacts/details/General";
import Document from "@/components/wallet/agency-contacts/details/Documents";
import Notes from "@/components/wallet/agency-contacts/details/Notes";
import Appointments from "@/components/wallet/agency-contacts/details/Appointments";
import BaseInputPagination from "@/components/form/BaseInputPagination";

export default {
  name: "AgencyContactsWalletTable",
  extends: template,
  data() {
    return {
      tabs: [
        { name: "General", text: "Generale" },
        { name: "Appointments", text: "Appuntamenti" },
        { name: "Notes", text: "Note" },
        { name: "Todo", text: "Attività" },
        { name: "Document", text: "Documenti" },
      ],
      selected: "General",
      modalResourceId: null,
      tabIndex: 0,
    };
  },
  components: {
    BaseIcon,
    BaseInputPagination,
    General,
    Appointments,
    Notes,
    ButtonGroupTab,
    Todo,
    Document,
  },
  methods: {
    customized() {},
    openNoteDetailModal(array) {
      this.$emit("open_note", array);
    },
    openTodoDetailModal(array) {
      this.$emit("open_todo", array);
    },
    openAppointmentDetailModal(array) {
      this.$emit("open_appointment", array);
    },
    openDocumentDetailModal(array) {
      this.$emit("open_document", array);
    },
  },
};
</script>
